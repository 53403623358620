<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="4">
        <h2>Raw Materials</h2>
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        md="4">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getItems(true)" />
          <v-btn
            color="success"
            :disabled="loading"
            to="/purchases/items/create"
            class="ml-2 mb-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :item-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template
            #[`item.bcSync`]="{ item }"
            class="d-flex justify-center">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
                  v-bind="attrs"
                  color="success"
                  v-on="on">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  v-bind="attrs"
                  color="error"
                  v-on="on">
                  mdi-sync-alert
                </v-icon>
              </template>
              <span v-if="item.bcSync && item.bcSync.updatedAt">
                Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
              </span>
              <span v-else>
                Not sync yet
              </span>
            </v-tooltip>
          </template>
          <!-- <template #[`item.imageURL`]="{ item }">
            <img
              :src="item.imageURL || noPicture"
              style="max-height: 80px;"
              class="mx-auto my-2" />
          </template> -->
          <template #[`item.description`]="{ item }">
            {{ item.description || '-' }}
          </template>
          <template #[`item.description2`]="{ item }">
            {{ item.description2 || '-' }}
          </template>
          <template #[`item.purchaseItemType`]="{ item }">
            {{ mapPurchaseType(item.purchaseItemType) }}
          </template>
          <template #[`item.onHandQty`]="{ item }">
            <v-btn
              text
              class="text-decoration-underline"
              :to="`/purchases/items/${item.id}/stock-movement`">
              {{ item.mainStock ? item.mainStock.onHandQty : '-' }} ({{ item.purchaseUOM }})
            </v-btn>
          </template>
          <template #[`item.onReservedQty`]="{ item }">
            <v-btn
              text
              class="text-decoration-underline"
              :to="`/purchases/items/${item.id}/stock-reserve`">
              {{ item.mainStock ? item.mainStock.onReservedQty : '-' }} ({{ item.purchaseUOM }})
            </v-btn>
          </template>
          <template #[`item.lotControl`]="{ item }">
            <v-icon
              v-if="item.lotControl"
              color="success">
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error">
              mdi-close
            </v-icon>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/purchases/items/${item.id}`">
              <v-icon small>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              small
              icon
              @click="confirmRemoveItem(item)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import PurchaseItemsProvider from '@/resources/PurchaseItemsProvider'
import NoPicture from '@/assets/image/no_picture_available.png'

const PurchaseItemsService = new PurchaseItemsProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: 'Sync',
          value: 'bcSync',
          width: 80,
          sortable: false
        },
        {
          text: 'รหัส',
          value: 'itemNo'
        },
        {
          text: 'ชื่อ',
          value: 'description'
        },
        {
          text: 'รายละเอียด',
          value: 'description2',
          sortable: false
        },
        {
          text: 'ประเภท',
          value: 'type',
          sortable: false
        },
        {
          text: 'ประเภทวัตถุดิบ',
          value: 'purchaseItemType',
          sortable: false
        },
        {
          text: 'Stock (สต็อก)',
          value: 'onHandQty',
          sortable: false
        },
        {
          text: 'Reserved (ติดจอง)',
          value: 'onReservedQty',
          sortable: false
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ],
      purchaseItemTypes: [
        {
          text: 'ผ้า',
          value: 'fabric'
        },
        {
          text: 'อะไหล่',
          value: 'material'
        },
        {
          text: 'อุปกรณ์แพ็ค',
          value: 'packaging'
        },
        {
          text: 'อุปกรณ์เสริม',
          value: 'supply'
        },
        {
          text: 'ค่าแรงตัดเย็บ',
          value: 'labour'
        },
        {
          text: 'ค่าขนส่ง',
          value: 'freight'
        }
      ],
      noPicture: NoPicture,
      items: [],
      query: {
        page: 1,
        limit: 10,
        role: null,
        status: null,
        search: ''
      },
      pageCount: 0,
      loading: false
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await PurchaseItemsService.getItems({
          page: this.query.page,
          limit: this.query.limit,
          search: this.query.search
        })

        this.items = data.results.map((r) => ({
          ...r,
          mainStock: r.stocks.find((s) => s.warehouse.id === 0)
        }))
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    confirmRemoveItem (item) {
      this.setModal({
        value: true,
        title: 'Remove Item',
        message: `Do you want to remove ${item.itemNo}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeItem(item.id)
      })
    },
    async removeItem (id) {
      try {
        this.loading = true

        await PurchaseItemsService.deleteItem(id)

        this.getItems(true)
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapPurchaseType (type) {
      const foundType = this.purchaseItemTypes.find((vt) => vt.value === type)

      return foundType?.text || type
    },
    mapStock (item) {
      const main = item.stocks.find((s) => s.warehouse.id === 0)

      return main || { onHandQty: 0, onReservedQty: 0 }
    },
    async syncAll () {
      try {
        this.loading = true

        await PurchaseItemsService.syncAll()
      } catch (error) {
        console.error('syncAll', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
